<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/commissioner' }"
              >服务专员</el-breadcrumb-item
            >
            <el-breadcrumb-item class="" style="color: #ff6618"
              >服务专员详情</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>

      <div style="background-color: #fafafa">
        <div class="max_width min_width">
          <div class="Introducebox" style="">
            <div style="display: flex; padding: 30px">
              <img
                :src="Introduce.img"
                style="
                  width: 280px;
                  height: 280px;
                  background: #ffddcc;
                  border-radius: 5px;
                  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
                "
                alt=""
              />
              <div style="margin-left: 30px; flex: 1">
                <div class="size_24" style="padding: 30px 0px">
                  {{ Introduce.name }}
                </div>
                <div class="size_18" style="padding-bottom: 20px">
                  服务专员电话：{{ Introduce.mobile }}
                </div>
                <div class="size_18" style="padding-bottom: 20px">
                  服务领域:

                  <span
                    class="c417"
                    v-for="(item, index) in options"
                    :key="item.ID"
                  >
                    <span v-for="(i, v) in num" :key="v">
                      <span v-if="index == i.value">
                        {{ item.name }}
                      </span>
                    </span>
                  </span>
                </div>
                <div class="size_18" style="padding-bottom: 20px">
                  工作单位:{{ Introduce.unit }}
                </div>
                <div
                  style="
                    width: 194px;
                    height: 40px;
                    background: #ff6618;
                    border-radius: 6px;
                    color: #fff;
                    line-height: 40px;
                    text-align: center;
                  "
                  @click="authentication()"
                >
                  申请服务专员服务
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #fafafa">
        <div class="max_width min_width">
          <div
            style="
              width: 1518px;
              height: 166px;
              border-radius: 5px;
              margin: 30px 0px;
            "
          >
            <div class="dis align_center" style="padding: 30px">
              <div style="display: flex">
                <div class="icon"></div>
                <div style="margin-left: 20px">专员介绍</div>
              </div>
              <div
                style="
                  width: 968px;
                  background: #ffddcc;
                  border-radius: 5px;
                  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
                "
              ></div>
            </div>
            <div style="margin: 30px">{{ Introduce.introduce }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="诉求标题"
      :visible.sync="dialogVisible"
      width="600px"
      center
    >
      <div>
        <el-form label-width="80px" :model="appeal">
          <el-form-item label="诉求标题" required>
            <el-input
              v-model="appeal.name"
              placeholder="请输入诉求标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="诉求类型" required>
            <el-select v-model="appeal.type" placeholder="请选择诉求类型">
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="诉求内容" required>
            <el-input
              placeholder="请输入诉求内容"
              type="textarea"
              :rows="5"
              v-model="appeal.content"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          style="
            width: 137px;
            height: 50px;
            background: #ff6618;
            border-radius: 10px;
            color: #fff;
          "
          @click="serve()"
          type="text"
        >
          确定发布
        </el-button>

        <el-button
          style="
            width: 137px;
            height: 50px;
            background: #cfcfcf;
            border-radius: 10px;
            color: #fff;
          "
          type="text"
          @click="dialogVisible = false"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>

    <app-footer />
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: "",
      id: "",
      Introduce: [],
      ids: "",
      options: "",
      num: "",
      appeal: {
        type: "",
        name: "",
        content: "",
      },
      dialogVisible: false,
      appealcontent: "",
    };
  },
  mounted() {
    this.id = this.$route.query.cid;
    console.log(this.id, "this.id");
    this.mechanismInfo();
    this.getcatelist();
  },
  methods: {
    specialist() {
      this.dialogVisible = true;
      this.appeal = {};
      // this.serve();
    },

    // 认证
    authentication() {
      if(localStorage.index != 1){
        this.$message({
            type: "warning",
            message: "该产品只限企业用户申请",
          });
      }else{
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1 && localStorage.index == 1) {
         
          this.specialist();
        } else if (res.status === 2 && localStorage.index == 1) {
          // this.$message({
          //   type: "warning",
          //   message: "请先认领",
          // });
          setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            location.href = "https://sso.smeqh.cn/realname/index";
          }, 2000);
        }
      });
    }
    },

    // 申请服务
    serve() {
      let data = {
        token: localStorage.eleToken,
        name: this.appeal.name,
        content: this.appeal.content,
        pro_cate_id: this.appeal.type,
        commissioner_id: this.id,
      };
      this.$post("order_commissioner_add", data).then((res) => {
        console.log(res.res);
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.dialogVisible = false;
        // this.postorderlist()
        // this.product = false;
      });
    },

    // 擅长领域
    getcatelist() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result;

        console.log(this.options, "this.options");
      });
    },

    productSelect() {
      let id = this.id;
      let data = {
        id: id,
        token: localStorage.eleToken,
      };

      this.$get("/select_commissioner_lst_d", data).then((res) => {
        console.log(res, "res");
        this.Introduce = res.result;
        let num;
        num = res.result.pro_cate_ids;

        // this.num = [...num];
        let arr = num.split(",");
        this.num = arr.map((item) => {
          return {
            value: item,
          };
        });

        console.log(this.num, "this.num");
      });
    },
    //获取高度
    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },

    mechanismInfo() {
      let data = { id: this.id };

      this.$get("/select_mechanism_info", data).then((res) => {
        console.log(res, "res");
        this.Introduce = res.result;
        this.ids = res.result.id;
        console.log(this.ids, " this.ids");
        this.productSelect();
      });
    },
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
.Introducebox {
  width: 1518px;
  background: #ffffff;
  border-radius: 5px;
  background-image: url("../../assets/image/sIntroduceimg.png");
}
::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fafafa;
}
</style>